import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  DisplayReviewList,
  Layout,
  SEO,
} from "../components";

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allReviewsJson {
        nodes {
          client
          title
          platform
          review
          date
          slug
          homeDesignSlug
          imagePath {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const reviews = data.allReviewsJson.nodes;

  return (
    <Layout>
      <SEO
        title="Client Reviews"
        description={"Client Reviews from Product Review, Google, etc."}
      />
      <BannerPage
        title={"Client Reviews"}
        subTitle={"Client Reviews from Product Review, Google, etc."}
      />
      <ContainerContent>
        <ContainerContentFixed>
          <DisplayReviewList reviews={reviews} />
        </ContainerContentFixed>
      </ContainerContent>
    </Layout>
  );
};

export default Reviews;
